
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Admin',
	data() {
		return {
			login: '',
			pass: '',
			errors: null
		};
	},
	methods: {
		handleSubmit() {
			if (this.pass) {
				this.$api.admin.login(this.login, this.pass)
					.then(() => {
						document.location.reload();
					})
					.catch(() => {
						// this.errors = 'Incorrect login or password';
					});
			}
		}
	}
});
